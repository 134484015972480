<template>
  <v-carousel
    style="width: 100%"
    height="auto"
    :next-icon="false"
    :prev-icon="false"
    :hide-delimiter-background="true"
  >
    <v-carousel-item v-for="imagePath in imagePaths" :key="imagePath">
      <v-sheet color="grey" height="100%" tile>
        <div class="square_content">
          <v-img :src="imagePath" max-height="300px" contain />
        </div>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<style>
.square_content {
  max-width: 500px;
  max-height: 500px;
  margin: 0 auto;
}
.square_content div {
  width: 100%;
  padding-top: 100%;
  margin-bottom: 40px;
}
</style>

<script>
export default {
  name: 'Carousel',
  props: ['imagePaths'],
};
</script>
